@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fontsource/inter/100.css";
@import "@fontsource/inter/200.css";
@import "@fontsource/inter/300.css";
@import "@fontsource/inter/400.css";
@import "@fontsource/inter/500.css";
@import "@fontsource/inter/600.css";
@import "@fontsource/inter/700.css";
@import "@fontsource/inter/800.css";
@import "@fontsource/inter/900.css";
@import "antd/dist/reset.css";

@import "./shared/styles/button.css";
@import "./shared/styles/pagination.css";
@import "./shared/styles/menu.css";
@import "./shared/styles/select.css";
@import "./shared/styles/input.css";
@import "./shared/styles/picker.css";
@import "./shared/styles/radio.css";
@import "./shared/styles/table.css";
@import "./shared/styles/upload.css";
@import "./shared/styles/icon.css";
@import "./shared/styles/slider.css";
@import "./shared/styles/sidebar.css";
@import "./shared/styles/collapse.css";
@import "./shared/styles/modal.css";
@import "./shared/styles/scrollbar.css";
@import "./shared/styles/timeline.css";

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: var(--font-color-brand);
  line-height: 129%;
  letter-spacing: 0.02em;
}

html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html {
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
}

body,
html,
div {
  padding: 0px;
  margin: 0px;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

li > a {
  margin-top: 5px;
}

li span {
  padding-top: 8px;
}

li span span {
  padding-top: 0px;
  margin-top: -8px;
}

*:disabled span {
  color: #63666a !important;
  background: transparent !important;
}

.rct-header-root > div {
  background: #002f5f !important;
}

.rct-item-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rct-hl-odd {
  background: #c6d1dd !important;
}

@layer base {
  .dark * {
    @apply !text-white !bg-gray-900;
  }
  .dark *:hover {
    @apply bg-gray-900 !important;
  }
  .dark a {
    @apply text-blue-400 hover:underline;
  }
  .dark button {
    @apply bg-blue-600 text-white hover:bg-blue-700;
  }
  .dark input,
  .dark textarea {
    @apply bg-gray-800 text-gray-200;
  }
  .dark input::placeholder,
  .dark textarea::placeholder {
    @apply text-gray-500;
  }
  .dark table {
    @apply bg-gray-800 text-gray-200;
  }
  .dark th,
  .dark td {
    @apply bg-gray-800 text-gray-200;
  }
  .dark tr {
    @apply bg-gray-800 !important;
  }
  .dark tr:hover {
    @apply bg-gray-800 !important;
  }

  .dark .ant-table {
    background-color: #1f2937 !important; /* bg-gray-800 */
    color: #e5e7eb !important; /* text-gray-200 */
  }
  .dark .ant-table-thead > tr > th {
    background-color: #1f2937 !important;
    color: #e5e7eb !important;
  }
  .dark .ant-table-tbody > tr > td {
    background-color: #1f2937 !important;
    color: #e5e7eb !important;
  }
  .dark .ant-table-tbody > tr:hover > td {
    background-color: #1f2937 !important; /* Убираем ховер */
  }
  .dark .ant-pagination-item a {
    color: white;
  }
  .dark .ant-table-filter-trigger {
    color: white;
  }
  .dark .ant-table-filter-dropdown {
    background-color: grey;
  }
  .dark .ant-table-column-sorter {
    color: grey;
  }
  .dark .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child,
  .dark .ant-menu-item.ant-menu-item-selected,
  .dark .ant-menu-item.ant-menu-item-selected span {
    background-color: #c6d1dd !important;
    color: black !important;
  }
  .dark .ant-menu-item.ant-menu-item-selected span svg {
    background-color: transparent !important;
  }
  .dark .ant-menu-item.ant-menu-item-selected span svg path {
    fill: #1f2937;
  }
  .dark .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child span {
    background-color: #c6d1dd !important;
    color: black !important;
  }
  .dark ul,
  .dark ol {
    @apply text-gray-200;
  }
  .dark blockquote {
    @apply text-gray-400;
  }
  .dark h1,
  .dark h2,
  .dark h3,
  .dark h4,
  .dark h5,
  .dark h6 {
    @apply text-gray-100;
  }
  .dark hr {
    @apply border-gray-700;
  }
  .dark img {
    @apply text-gray-200;
  }
  .dark code,
  .dark pre {
    @apply bg-gray-800 text-gray-200;
  }
  .dark nav {
    @apply bg-gray-800 text-gray-200;
  }
  .dark footer {
    @apply bg-gray-800 text-gray-400;
  }
  .dark header {
    @apply bg-gray-800 text-gray-200;
  }
  .dark aside {
    @apply bg-gray-800 text-gray-200;
  }
  .dark section {
    @apply bg-gray-900 text-gray-200;
  }
  .dark article {
    @apply bg-gray-900 text-gray-200;
  }
  .dark figure {
    @apply bg-gray-800 text-gray-200;
  }
  .dark figcaption {
    @apply text-gray-400;
  }
  .dark div {
    background-color: #00172f;
    color: white;
  }
  .dark span {
    color: white;
  }
  .dark .ant-popover-inner-content,
  .dark .ant-descriptions-item-content {
    color: white !important;
  }
  .dark .ant-menu,
  .dark .ant-menu-item {
    background-color: #00172f !important;
  }
  .dark .ant-select-selection-search,
  .dark .ant-picker-input input::placeholder {
    background-color: #00172f;
    color: white;
  }
}
