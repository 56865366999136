.ant-pagination-item.ant-pagination-item-active {
  border-color: #002f5f !important;
}

.ant-pagination-item.ant-pagination-item-active a {
  color: #002f5f !important;
}

.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active a {
  color: #002f5f !important;
}

.ant-pagination-item-ellipsis {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .ant-pagination-item {
    font-size: 12px;
    padding: 0 6px;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .ant-pagination-item.ant-pagination-item-active {
    border-color: #002f5f !important;
  }

  .ant-pagination-item.ant-pagination-item-active a {
    color: #002f5f !important;
  }

  .ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active a {
    color: #002f5f !important;
  }

  .ant-pagination-item-ellipsis {
    margin-top: 10px;
  }
}
