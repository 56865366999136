.ant-radio-inner {
  background: #002f5f !important;
  margin-top: 0.5px !important;
}

.ant-radio-inner:hover {
  background: #002f5f !important;
}

.ant-radio-button .ant-radio-button-checked {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
