.ant-table-thead .ant-table-cell {
  background: #c6d1dd !important;
}

.ant-table-thead .ant-table-cell:hover {
  background: #dddedf !important;
}

.ant-table-cell {
  border-bottom: 1px #c6d1dd solid !important;
}

.ant-table .small-row {
  height: 100px !important;
}
