.ant-picker.css-dev-only-do-not-override-10ed4xt:hover {
  border-color: #002f5f !important;
}

.ant-picker.css-dev-only-do-not-override-10ed4xt:focus {
  border-color: #002f5f !important;
}

.ant-picker-cell-inner::before {
  border-color: #002f5f !important;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected div {
  background: #002f5f !important;
}

.ant-picker.ant-picker-large.css-10ed4xt.w-full {
  border-color: #c6d1dd !important;
}

.ant-picker.ant-picker-large.css-10ed4xt.w-full:hover {
  border-color: #c6d1dd !important;
}

.ant-picker.ant-picker-large.css-10ed4xt.w-full:focus {
  border-color: #c6d1dd !important;
}

.ant-picker.ant-picker-large.css-10ed4xt.w-full:focus-visible {
  border-color: #c6d1dd !important;
}

.ant-picker.ant-picker-large.css-10ed4xt.w-full:focus-within {
  border-color: #c6d1dd !important;
}

.ant-picker.ant-picker-large.css-dev-only-do-not-override-10ed4xt.w-full.ant-picker-focused {
  border-color: #c6d1dd !important;
}

.ant-picker-cell-in-view.ant-picker-cell-disabled .ant-picker-cell-inner {
  cursor: not-allowed !important;
}
