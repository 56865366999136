.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-dev-only-do-not-override-10ed4xt:hover {
  border-color: #63666a !important;
}
.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-dev-only-do-not-override-10ed4xt:focus {
  border-color: #63666a !important;
}
.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-dev-only-do-not-override-10ed4xt:focus-visible {
  border-color: #63666a !important;
}
.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-dev-only-do-not-override-10ed4xt:focus-within {
  border-color: #63666a !important;
}

.ant-input.ant-input-lg.css-dev-only-do-not-override-10ed4xt:hover {
  border-color: #002f5f !important;
}
.ant-input.ant-input-lg.css-dev-only-do-not-override-10ed4xt:focus {
  border-color: #002f5f !important;
}

.ant-input.ant-input-lg.css-10ed4xt:hover {
  border-color: #002f5f !important;
}

.ant-input.ant-input-lg.css-10ed4xt:focus {
  border-color: #002f5f !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-dev-only-do-not-override-10ed4xt {
  border-color: none !important;
  outline: none !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-dev-only-do-not-override-10ed4xt:hover {
  border-color: none !important;
  outline: none !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-dev-only-do-not-override-10ed4xt:focus {
  border-color: #002f5f !important;
  outline: none !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-10ed4xt {
  border-color: none !important;
  outline: none !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-10ed4xt:hover {
  border-color: #002f5f !important;
  outline: none !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-10ed4xt:focus {
  border-color: #002f5f !important;
  outline: none !important;
}

.ant-input.css-dev-only-do-not-override-10ed4xt:hover {
  border-color: #002f5f !important;
}

.ant-input.css-dev-only-do-not-override-10ed4xt:focus {
  border-color: #002f5f !important;
}

.ant-input.ant-input-lg.css-dev-only-do-not-override-10ed4xt:hover {
  border-color: #002f5f !important;
}

.ant-input.ant-input-lg.css-dev-only-do-not-override-10ed4xt:focus {
  border-color: #002f5f !important;
}

.ant-input.css-10ed4xt {
  border-color: #002f5f !important;
}

.ant-input.css-10ed4xt:hover {
  border-color: #002f5f !important;
}

.ant-input.css-10ed4xt:focus {
  border-color: #002f5f !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-10ed4xt {
  border-color: #c6d1dd !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-10ed4xt:hover {
  border-color: #c6d1dd !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-10ed4xt:focus {
  border-color: #c6d1dd !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-10ed4xt:focus-visible {
  border-color: #c6d1dd !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-password-large.ant-input-affix-wrapper-lg.css-10ed4xt:focus-within {
  border-color: #c6d1dd !important;
}

.ant-input.ant-input-lg.css-10ed4xt {
  border-color: #c6d1dd !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-input-number {
  width: 100%;
}

.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number:focus-visible,
.ant-input-number:focus-within {
  border-color: #63666a !important;
}

.no-arrows .ant-input-number-handler-wrap {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  border-radius: 0;
}
