.ant-upload {
  width: 100%;
}

.ant-upload-list-item-container {
  height: 22px;
  border-radius: 3px;
}

.ant-upload-list-item.ant-upload-list-item-done {
  margin: 0px !important;
  border-radius: 10px !important;
}
