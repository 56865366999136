@tailwind components;

@layer components {
  .btn-primary--dark {
    @apply mb-2.5 w-auto !bg-blue-primary
     hover:!brightness-125 flex
      justify-between items-center cursor-pointer;
  }

  .btn-filter--x_mark {
    @apply w-12.5 ml-2.5 flex items-center 
    justify-center !bg-blue-primary
    hover:!bg-blue-primary hover:!brightness-150 cursor-pointer;
  }

  .btn-edit {
    @apply text-green-100 text-[22.5px] cursor-pointer hover:scale-125 transition;
  }

  .btn-remove {
    @apply text-red-100 text-[22.5px] cursor-pointer hover:scale-125 transition;
  }

  .btn-settings {
    @apply text-blue-primary text-[22.5px] cursor-pointer hover:scale-125 transition;
  }

  .btn-additional {
    @apply text-gray-600 text-[22.5px] cursor-pointer hover:scale-125 transition;
  }

  .btn-purple {
    @apply text-purple-600 text-[22.5px] cursor-pointer hover:scale-125 transition;
  }

  .custom-icon {
    @apply !w-[22.5px] !h-[22.5px] cursor-pointer hover:scale-110;
  }
}

.ant-btn.css-dev-only-do-not-override-10ed4xt.ant-btn-default:hover {
  color: #002f5f !important;
  border-color: #002f5f !important;
}

.ant-btn.css-dev-only-do-not-override-10ed4xt.ant-btn-primary:disabled {
  background: transparent !important;
  opacity: 0.5;
}

.ant-btn.css-10ed4xt.ant-btn-primary:disabled {
  background: transparent !important;
  opacity: 0.5;
}

.ant-btn.css-10ed4xt.ant-btn-primary.items-center.justify-center:hover {
  background: #002f5f !important;
}

.ant-btn.css-10ed4xt.ant-btn-default:hover {
  border: 1px solid #002f5f !important;
  color: #002f5f !important;
}

.ant-btn.css-dev-only-do-not-override-10ed4xt.ant-btn-link.ant-btn-sm {
  color: #002f5f !important;
}

.ant-btn.css-dev-only-do-not-override-10ed4xt.ant-btn-primary.ant-btn-sm {
  background: #002f5f !important;
}

.ant-btn.css-10ed4xt.ant-btn-primary.ant-btn-sm {
  background: #002f5f !important;
}

.ant-btn.css-dev-only-do-not-override-10ed4xt.ant-btn-primary.ant-btn-sm span {
  padding: 0px !important;
}

.ant-btn.css-10ed4xt.ant-btn-primary.ant-btn-sm span {
  padding: 0px !important;
}

.ant-btn.css-dev-only-do-not-override-10ed4xt.ant-btn-primary.ant-btn-sm {
  background: #002f5f !important;
}

.ant-btn.css-10ed4xt.ant-btn-primary.ant-btn-sm {
  background: #002f5f !important;
}
