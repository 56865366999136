.ant-menu-item {
  color: #fff !important;
}

.ant-menu-item:hover {
  background: #92a3bc !important;
}

.ant-menu-submenu-title {
  color: #fff !important;
}

.ant-menu-submenu-title:hover {
  background: #92a3bc !important;
}

.ant-menu-title-content {
  padding: 0px;
}

.ant-menu-item.ant-menu-item-selected {
  background: #fff !important;
  color: #002f5f !important;
  width: 100.2% !important;
  border-radius: 0px !important;
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  margin-left: 0px;
}

.ant-menu-submenu-title {
  margin: 0px !important;
}

.ant-dropdown-menu-item {
  border-color: #002f5f !important;
  background: #fff !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  background: #002f5f !important;
  color: white !important;
}

.ant-dropdown-menu-title-content {
  padding: 0px !important;
}

.anticon.anticon-user.ant-menu-item-icon {
  margin-top: -10px !important;
}

.anticon.anticon-api.ant-menu-item-icon {
  margin-top: -10px !important;
}

.anticon.anticon-file-text.ant-menu-item-icon {
  margin-top: -10px !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  background: #c6d1dd !important;
}
