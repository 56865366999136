.ant-slider-track.ant-slider-track-1 {
  background: #002f5f !important;
}

.ant-slider-handle.ant-slider-handle-1::after {
  box-shadow: none !important;
  border: 1px solid #002f5f !important ;
  scale: 140% !important;
}

.ant-slider-handle.ant-slider-handle-2::after {
  box-shadow: none !important;
  border: 1px solid #002f5f !important ;
  scale: 140% !important;
}
