.ant-select-selector:hover {
  border-color: #002f5f !important;
}

.ant-select-selector:focus {
  border-color: #002f5f !important;
}

.ant-select-selector:focus-visible {
  border-color: #63666a !important;
}

.ant-select-selector:focus-within {
  border-color: #63666a !important;
}

.ant-select-selection-item-content {
  margin-top: 5px !important;
}

.ant-select-selection-item-remove {
  margin-top: -2px !important;
}

.ant-select-selector {
  border-color: #c6d1dd !important;
}

.ant-select-selector:hover {
  border-color: #c6d1dd !important;
}

.ant-select-selector:focus {
  border-color: #c6d1dd !important;
}

.ant-select-selector:focus-visible {
  border-color: #c6d1dd !important;
}

.ant-select-selector:focus-within {
  border-color: #c6d1dd !important;
}

.custom-select-error .ant-select-selector {
  background-color: #f68787 !important;
}

.custom-select-success .ant-select-selector {
  background-color: #a3f7c0 !important;
}

.custom-select-warning .ant-select-selector {
  background-color: #f1eb9a !important;
}

.ant-select-selection-overflow {
  max-height: 250px !important;
  overflow: auto;
}

.ant-dropdown-trigger.ant-table-filter-trigger.active {
  color: #002f5f !important;
}

.ant-select-selection-item-content {
  margin: 0 !important;
}
